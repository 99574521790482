// Generated by Framer (12ecc5e)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Feather } from "https://framerusercontent.com/modules/f0DboytQenYh21kfme7W/zb1zVBMZJKgPMiedOi0y/Feather.js";
const FeatherFonts = getFonts(Feather);

const serializationHash = "framer-jfVQ3"

const variantClassNames = {b3Ny45r9l: "framer-v-1uj08hl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const radiusForCorner = (value, cornerIndex) => {
    if (typeof value === "number" && Number.isFinite(value)) return Math.max(0, value) + "px";
    if (typeof value !== "string" || typeof cornerIndex !== "number") return undefined;
    const segments = value.split(" ");
    return segments[cornerIndex] || segments[cornerIndex - 2] || segments[0];
};


const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({background, height, id, radius, width, ...props}) => { return {...props, IcbIOvI7p: radius ?? props.IcbIOvI7p ?? "0px", nBCEJpFXD: background ?? props.nBCEJpFXD ?? "var(--token-f45ad893-0f23-4ab6-9771-459e739fac3d, rgb(197, 150, 72))"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;background?: string;radius?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, nBCEJpFXD, IcbIOvI7p, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "b3Ny45r9l", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1uj08hl", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"b3Ny45r9l"} ref={ref ?? ref1} style={{"--border-bottom-width": "0.5px", "--border-color": "var(--token-f45ad893-0f23-4ab6-9771-459e739fac3d, rgb(197, 150, 72))", "--border-left-width": "0.5px", "--border-right-width": "0.5px", "--border-style": "solid", "--border-top-width": "0.5px", backgroundColor: nBCEJpFXD, borderBottomLeftRadius: radiusForCorner(IcbIOvI7p, 3), borderBottomRightRadius: radiusForCorner(IcbIOvI7p, 2), borderTopLeftRadius: radiusForCorner(IcbIOvI7p, 0), borderTopRightRadius: radiusForCorner(IcbIOvI7p, 1), ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO1pvbmEgUHJvIExpZ2h0", "--framer-font-family": "\"Zona Pro Light\", \"Zona Pro Light Placeholder\", sans-serif", "--framer-font-size": "12px", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Καλέστε μας</motion.p></React.Fragment>} className={"framer-1kfxhcw"} fonts={["CUSTOM;Zona Pro Light"]} layoutDependency={layoutDependency} layoutId={"HzW22c1yB"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/><ComponentViewportProvider ><motion.div className={"framer-1pwbvg7-container"} layoutDependency={layoutDependency} layoutId={"pPijOqaxc-container"}><Feather color={"rgb(255, 255, 255)"} height={"100%"} iconSearch={"Home"} iconSelection={"phone"} id={"pPijOqaxc"} layoutId={"pPijOqaxc"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jfVQ3.framer-6qpd2, .framer-jfVQ3 .framer-6qpd2 { display: block; }", ".framer-jfVQ3.framer-1uj08hl { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 20px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-jfVQ3 .framer-1kfxhcw { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-jfVQ3 .framer-1pwbvg7-container { flex: none; height: 11px; position: relative; width: 11px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-jfVQ3.framer-1uj08hl { gap: 0px; } .framer-jfVQ3.framer-1uj08hl > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-jfVQ3.framer-1uj08hl > :first-child { margin-left: 0px; } .framer-jfVQ3.framer-1uj08hl > :last-child { margin-right: 0px; } }", ".framer-jfVQ3[data-border=\"true\"]::after, .framer-jfVQ3 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 54.5
 * @framerIntrinsicWidth 135
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"nBCEJpFXD":"background","IcbIOvI7p":"radius"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerGb21b5OkG: React.ComponentType<Props> = withCSS(Component, css, "framer-jfVQ3") as typeof Component;
export default FramerGb21b5OkG;

FramerGb21b5OkG.displayName = "Call Us";

FramerGb21b5OkG.defaultProps = {height: 54.5, width: 135};

addPropertyControls(FramerGb21b5OkG, {nBCEJpFXD: {defaultValue: "var(--token-f45ad893-0f23-4ab6-9771-459e739fac3d, rgb(197, 150, 72)) /* {\"name\":\"Golden Brown\"} */", title: "Background", type: ControlType.Color}, IcbIOvI7p: {defaultValue: "0px", title: "Radius", type: ControlType.BorderRadius}} as any)

addFonts(FramerGb21b5OkG, [{explicitInter: true, fonts: [{family: "Zona Pro Light", source: "custom", url: "https://framerusercontent.com/assets/LN2M82vvHgKujXBQYgRgAhc2CU.woff2"}]}, ...FeatherFonts], {supportsExplicitInterCodegen: true})